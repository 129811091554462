import {
  GET_SITE_INFO_SUCCESS,
  GET_SITE_INFO_FAIL,
  UPDATE_SITE_INFO_SUCCESS,
  UPDATE_SITE_INFO_FAIL,
  ADD_SITE_INFO_SUCCESS,
  ADD_SITE_INFO_FAIL,
  RESET_SITE_INFO_FLAG,
} from "./actionTypes"

const INIT_STATE = {
  siteInfo: {},
  siteInfoError: {},
  updateSiteInfoError: null,
  updateSiteInfoSuccess: null,
  addSiteInfoError: null,
  addSiteInfoSuccess: null,
}

const siteInfo = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SITE_INFO_SUCCESS:
      return {
        ...state,
        siteInfo: action.payload,
        siteInfoError: null,
      }
    case GET_SITE_INFO_FAIL:
      return {
        ...state,
        siteInfoError: action.payload,
      }

    case UPDATE_SITE_INFO_SUCCESS:
      return {
        ...state,
        siteInfo: action.payload,
        updateSiteInfoSuccess: "updated successfully",
        updateSiteInfoError: null,
      }
    case UPDATE_SITE_INFO_FAIL:
      return {
        ...state,
        updateSiteInfoError: action.payload,
        updateSiteInfoSuccess: null,
      }

    case ADD_SITE_INFO_SUCCESS:
      return {
        ...state,
        siteInfo: action.payload,
        addSiteInfoSuccess: "updated successfully",
        addSiteInfoError: null,
      }
    case ADD_SITE_INFO_FAIL:
      return {
        ...state,
        addSiteInfoError: action.payload,
        addSiteInfoSuccess: null,
      }

    case RESET_SITE_INFO_FLAG:
      return {
        ...state,
        updateSiteInfoSuccess: null,
        updateSiteInfoError: null,
        addSiteInfoSuccess: null,
        addSiteInfoError: null,
      }
    default:
      return state
  }
}

export default siteInfo
