import {
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPDATE_PROFILE__SUCCESS,
  UPDATE_PROFILE_FAIL,
  RESET_PROFILE_FLAG,
} from "./actionTypes"

const INIT_STATE = {
  profile: {},
  profileError: {},
  updateProfileError: null,
  updateProfileSuccess: null,
}

const adminProfile = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        profileError: null,
      }
    case GET_PROFILE_FAIL:
      return {
        ...state,
        profileError: action.payload,
      }

    case UPDATE_PROFILE__SUCCESS:
      return {
        ...state,
        profile: action.payload,
        updateProfileSuccess: "updated successfully",
        updateProfileError: null,
      }
    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        updateProfileError: action.payload,
        updateProfileSuccess: null,
      }

    case RESET_PROFILE_FLAG:
      return {
        ...state,
        updateProfileSuccess: null,
        updateProfileError: null,
      }

    default:
      return state
  }
}

export default adminProfile
