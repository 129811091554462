import React, { useEffect, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
import DeleteModal from "../../components/Common/DeleteModal"

import {
  deletePurchaseEvent,
  getPurchaseEvent,
  getPurhcaseEventApprentice,
} from "store/purchase/actions"

import { OrderId, CommonField, Collection } from "../Booked-event/BookedCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap"

const Purchase = () => {
  const navigate = useNavigate()
  //meta title
  document.title = "Purchase | Tacticulture-Admin"
  const [order, setOrder] = useState(null)

  const toggleViewModal = id => {
    dispatch(getPurhcaseEventApprentice(id))
    navigate(`/purchase-apprentice?purchaseId=${id}`)
  }

  const dispatch = useDispatch()
  const { purchaseEvent } = useSelector(state => ({
    purchaseEvent: state.Purchase.purchaseEvent,
  }))

  useEffect(() => {
    if (purchaseEvent && !purchaseEvent.length) {
      dispatch(getPurchaseEvent())
    }
  }, [dispatch])

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = order => {
    setOrder(order)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    if (order && order.id) {
      dispatch(deletePurchaseEvent(order.id))
      setDeleteModal(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Purchase ID",
        accessor: "id",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Instructure",
        accessor: "created_by.username",
        filterable: true,
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },
      {
        Header: "Event Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },
      {
        Header: "Event Price",
        accessor: "cost_per_spot",
        filterable: true,
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },
      {
        Header: "Collection",
        accessor: "collection",
        filterable: true,
        Cell: cellProps => {
          return <Collection {...cellProps} />
        },
      },
      {
        Header: "Total Apprentice",
        accessor: "total_apprentice",
        filterable: true,
        Cell: cellProps => {
          return <Collection {...cellProps} />
        },
      },
      {
        Header: "View Details",
        accessor: "view",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => toggleViewModal(cellProps.row.original.id)}
            >
              View Details
            </Button>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDelete(orderData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    [],
  )

  const handleRowClick = row => {
    dispatch(getPurhcaseEventApprentice(row.original.id))
    navigate(`/purchase-apprentice?purchaseId=${row.original.id}`)
  }
  return (
    <>
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteOrder}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div className="page-content">
          <div className="container-fluid">
            <h3 className="mb-4">Manage Purchase</h3>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={purchaseEvent}
                      getRowProps={row => ({
                        onClick: e => {
                          if (
                            !e.target.closest("[data-column='course_url']") &&
                            !e.target.closest("[data-column='status']") &&
                            !e.target.closest("[data-column='action']")
                          ) {
                            handleRowClick(row)
                          }
                        },
                        style: { cursor: "pointer" }, // Make cursor pointer for rows
                      })}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={10}
                      forFilter={true}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    </>
  )
}

export default Purchase
