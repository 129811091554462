import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_USERS,
  DELETE_USER,
  GET_USER_DETAIL,
  UPDATE_USERR,
  UPDATE_SUBSCRIPTION,
} from "./actionTypes"
import {
  getUsersSuccess,
  getUsersFail,
  getUserDetailSuccess,
  deleteUserSuccess,
  deleteUserFail,
  getUserDetailFail,
  updateUserrSuccess,
  updateUserrFail,
  updateSubscriptionSucess,
  updateSubscriptionFail,
} from "./actions"

import { showLoader, hideLoader } from "store/actions"
import { del, get, putt } from "helpers/api_helper"

function* fetchUsers() {
  try {
    yield put(showLoader())
    const response = yield call(get, "/api/v1/admin/users/user/")

    yield put(getUsersSuccess(response.results))
  } catch (error) {
    yield put(getUsersFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* updateUser({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      putt,
      `/api/v1/admin/users/change-user-status/${data.userId}/`,
      { is_active: data.value },
    )
    yield put(updateUserrSuccess(data))
  } catch (error) {
    yield put(updateUserrFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* updateSubscription({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      putt,
      `/api/v1/admin/users/subscriptions/assign-plan`,
      { user_id: data.userId, plan_id: data.planId },
    )
    yield put(updateSubscriptionSucess(data))
  } catch (error) {
    yield put(updateSubscriptionFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* fetchUserDetail({ payload: id }) {
  try {
    yield put(showLoader())
    const response = yield call(get, `/api/v1/admin/users/manage-users/${id}`)

    yield put(getUserDetailSuccess(response))
  } catch (error) {
    yield put(getUserDetailFail(error))
  } finally {
    yield put(hideLoader())
  }
}
function* onDeleteUser({ payload: user }) {
  try {
    yield put(showLoader())
    const response = yield call(del, `/api/v1/admin/users/manage-users/${user}`)
    yield put(deleteUserSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* userSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_DETAIL, fetchUserDetail)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(UPDATE_USERR, updateUser)
  yield takeEvery(UPDATE_SUBSCRIPTION, updateSubscription)
}

export default userSaga
