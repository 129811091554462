import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_CATEGORY_DETAIL,
  GET_CATEGORY_DETAIL_SUCCESS,
  GET_CATEGORY_DETAIL_FAIL,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  ADD_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  RESET_ADD_CATEGORY_FLAG,
} from "./actionTypes"

export const getCategories = () => ({
  type: GET_CATEGORIES,
})

export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories,
})

export const getCategoriesFail = error => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
})

export const updateCategory = value => ({
  type: UPDATE_CATEGORY,
  payload: value,
})

export const updateCategorySuccess = category => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload: category,
})

export const updateCategoryFail = error => ({
  type: UPDATE_CATEGORY_FAIL,
  payload: error,
})

export const getCategoryDetail = id => ({
  type: GET_CATEGORY_DETAIL,
  payload: id,
})

export const getCategoryDetailSuccess = category => ({
  type: GET_CATEGORY_DETAIL_SUCCESS,
  payload: category,
})

export const getCategoryDetailFail = error => ({
  type: GET_CATEGORY_DETAIL_FAIL,
  payload: error,
})

export const deleteCategory = id => ({
  type: DELETE_CATEGORY,
  payload: id,
})

export const deleteCategorySuccess = category => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload: category,
})

export const deleteCategoryFail = error => ({
  type: DELETE_CATEGORY_FAIL,
  payload: error,
})

export const addCategory = data => ({
  type: ADD_CATEGORY,
  payload: data,
})

export const addCategorySuccess = category => ({
  type: ADD_CATEGORY_SUCCESS,
  payload: category,
})

export const addCategoryFail = error => ({
  type: ADD_CATEGORY_FAIL,
  payload: error,
})

export const resetAddCategoryAlert = () => ({
  type: RESET_ADD_CATEGORY_FLAG,
})
