import { call, put, takeEvery } from "redux-saga/effects"

// site info Redux States
import { GET_SITE_INFO, UPDATE_SITE_INFO, ADD_SITE_INFO } from "./actionTypes"
import {
  getSiteInfoSuccess,
  getSiteInfoFail,
  updateSiteInfoSuccess,
  updateSiteInfoFail,
  addSiteInfoSuccess,
  addSiteInfoFail,
} from "./actions"

import { showLoader, hideLoader } from "store/actions"

import { get, putt, post } from "helpers/api_helper"

function* fetchSiteInfo() {
  try {
    yield put(showLoader())
    const response = yield call(get, "/api/v1/site-settings/site-info/")

    yield put(getSiteInfoSuccess(response.results[0]))
  } catch (error) {
    yield put(getSiteInfoFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* updateSiteInfo({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      putt,
      `/api/v1/site-settings/site-info/${data.id}/`,
      data,
    )
    yield put(updateSiteInfoSuccess(response))
  } catch (error) {
    yield put(updateSiteInfoFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* addSiteInfo({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(post, `/api/v1/site-settings/site-info/`, data)
    yield put(addSiteInfoSuccess(response))
  } catch (error) {
    yield put(addSiteInfoFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* siteInfoSaga() {
  yield takeEvery(GET_SITE_INFO, fetchSiteInfo)
  yield takeEvery(UPDATE_SITE_INFO, updateSiteInfo)
  yield takeEvery(ADD_SITE_INFO, addSiteInfo)
}

export default siteInfoSaga
