import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

//import action
import { getDashboardChartData, getDashboardData } from "../../store/actions"

// Pages Components
import WelcomeComp from "./WelcomeComp"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import Apaexlinecolumn from "./Chart"

const Dashboard = props => {
  const { dashboardData, dashboardChartData } = useSelector(state => ({
    dashboardChartData: state.dashboard.dashboardChartData,
    dashboardData: state.dashboard.dashboardData,
  }))

  const dispatch = useDispatch()

  useEffect(() => {
    if (dashboardData && !dashboardData.length) {
      dispatch(getDashboardData())
      dispatch(getDashboardChartData())
    }
  }, [dispatch])

  //meta title
  document.title = "Dashboard | Tacticulture-Admin"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <WelcomeComp />
            </Col>
            <Col xl="12">
              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h3 className="mb-0">Total Events</h3>
                          <h4 className="mb-0">{dashboardData.event_count}</h4>
                        </div>

                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "bx " + "bx-copy-alt" + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h3 className="mb-0">Total Users</h3>
                          <h4 className="mb-0">{dashboardData.user_count}</h4>
                        </div>

                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "bx " + "bx-copy-alt" + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h3 className="mb-0">Total Event Bookings</h3>
                          <h4 className="mb-0">
                            {dashboardData.booking_count}
                          </h4>
                        </div>

                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "bx " + "bx-copy-alt" + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h3 className="mb-0">Past Events</h3>
                          <h4 className="mb-0">
                            {dashboardData.past_events_count}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h3 className="mb-0">Current Events</h3>
                          <h4 className="mb-0">
                            {dashboardData.upcoming_events_counts}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Card>
                <CardBody>
                  <div className="clearfix"></div>
                  <Apaexlinecolumn
                    data={dashboardChartData}
                    dataColors='["--bs-danger","--bs-primary", "--bs-success"]'
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
