/* manage waiver */
export const GET_WAIVER = "GET_WAIVER"
export const GET_WAIVER_SUCCESS = "GET_WAIVER_SUCCESS"
export const GET_WAIVER_FAIL = "GET_WAIVER_FAIL"

export const CREATE_WAIVER = "CREATE_WAIVER"
export const CREATE_WAIVER_SUCCESS = "CREATE_WAIVER_SUCCESS"
export const CREATE_WAIVER_FAIL = "CREATE_WAIVER_FAIL"

export const UPDATE_WAIVER = "UPDATE_WAIVER"
export const UPDATE_WAIVER_SUCCESS = "UPDATE_WAIVER_SUCCESS"
export const UPDATE_WAIVER_FAIL = "UPDATE_WAIVER_FAIL"

/* Reset Waiver flag*/
export const RESET_WAIVER_FLAG = "RESET_WAIVER_FLAG"
