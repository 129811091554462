/* GET SITE INFO */
export const GET_SITE_INFO = "GET_SITE_INFO"
export const GET_SITE_INFO_SUCCESS = "GET_SITE_INFO_SUCCESS"
export const GET_SITE_INFO_FAIL = "GET_SITE_INFO_FAIL"

/*UPDATE SITE INFO */
export const UPDATE_SITE_INFO = "UPDATE_SITE_INFO"
export const UPDATE_SITE_INFO_SUCCESS = "UPDATE_SITE_INFO_SUCCESS"
export const UPDATE_SITE_INFO_FAIL = "UPDATE_SITE_INFO_FAIL"
export const RESET_SITE_INFO_FLAG = "RESET_SITE_INFO_FLAG"

/*Add SITE INFO details if not exist */
export const ADD_SITE_INFO = "ADD_SITE_INFO"
export const ADD_SITE_INFO_SUCCESS = "ADD_SITE_INFO_SUCCESS"
export const ADD_SITE_INFO_FAIL = "ADD_SITE_INFO_FAIL"
