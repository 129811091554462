import React, { useEffect, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
import DeleteModal from "../../components/Common/DeleteModal"

import { OrderId, CommonField } from "./CategoryCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap"
import { getCategories, getCategoryDetail, deleteCategory } from "store/actions"

function Categories() {
  const navigate = useNavigate()
  //meta title
  const dispatch = useDispatch()
  document.title = "Categories | Tacticulture-Admin"

  const [delCategory, setDelCategory] = useState(null)

  const toggleViewModal = id => {
    dispatch(getCategoryDetail(id))
    navigate(`/category-detail?categoryId=${id}`)
  }

  const { categories } = useSelector(state => ({
    categories: state.Categories.categories,
  }))

  useEffect(() => {
    if (categories && !categories.length) {
      dispatch(getCategories())
    }
  }, [dispatch])

  //delete user
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = category => {
    setDelCategory(category)
    setDeleteModal(true)
  }

  const handleDeleteCategory = () => {
    if (delCategory && delCategory.id) {
      dispatch(deleteCategory(delCategory.id))
      setDeleteModal(false)
      setTimeout(() => {
        dispatch(getCategories())
      }, 1000)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Category ID",
        accessor: "id",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Category Name",
        accessor: "event_categories",
        filterable: true,
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },
      {
        Header: "Category Slug",
        accessor: "slug_name",
        filterable: true,
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={e => {
                  e.preventDefault()
                  toggleViewModal(cellProps.row.original.id)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDelete(orderData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    [],
  )

  return (
    <React.Fragment>
      {/* <EventsDetailModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <h3 className="mb-4">Manage Category</h3>
          {/* <Breadcrumbs title="Users" breadcrumbItem="List" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-end">
                    <Button
                      color="primary"
                      onClick={() => navigate("/add-category")}
                    >
                      Add New Category
                    </Button>
                  </div>

                  <TableContainer
                    className="userTable"
                    columns={columns}
                    data={categories}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    forFilter={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Categories
