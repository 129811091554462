import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_BOOKEDEVENTS,
  DELETE_BOOKED_EVENT,
  GET_BOOKEDEVENT_DETAIL,
} from "./actionTypes"
import {
  getBookedEventSuccess,
  getBookedEventFail,
  getBookedEventDetailSuccess,
  getBookedEventDetailFail,
  deleteBookedEventSuccess,
  deleteBookedEventFail,
} from "./actions"
import { showLoader, hideLoader } from "store/actions"
import { get, del } from "helpers/api_helper"

function* fetchBookedEvents() {
  try {
    yield put(showLoader())
    const response = yield call(get, "/api/v1/admin/event/booked-event-list/")
    yield put(getBookedEventSuccess(response.results))
  } catch (error) {
    yield put(getBookedEventFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* onDeleteBookedEvent({ payload: event }) {
  try {
    yield put(showLoader())
    const response = yield call(
      del,
      `/api/v1/admin/event/booked-event-delete/${event}/`,
    )

    yield put(deleteBookedEventSuccess(event))
  } catch (error) {
    yield put(deleteBookedEventFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* fetchBookedEventDetail({ payload: event }) {
  try {
    yield put(showLoader())
    const response = yield call(
      get,
      `/api/v1/admin/event/booked-event/${event}`,
    )
    yield put(getBookedEventDetailSuccess(response))
  } catch (error) {
    yield put(getBookedEventDetailFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* bookedEventSaga() {
  yield takeEvery(GET_BOOKEDEVENTS, fetchBookedEvents)
  yield takeEvery(GET_BOOKEDEVENT_DETAIL, fetchBookedEventDetail)
  yield takeEvery(DELETE_BOOKED_EVENT, onDeleteBookedEvent)
}

export default bookedEventSaga
