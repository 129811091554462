import React, { useState, useEffect } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css" // Import Quill's styles
import {
  getWaiver,
  updateWaiver,
  createWaiver,
  resetWaiverFlag,
} from "store/waiver/actions"
import { useDispatch, useSelector } from "react-redux"
import { Alert } from "reactstrap"

const ManageWaiver = () => {
  document.title = "Manage Waiver | Tacticulture-Admin"

  const dispatch = useDispatch()

  // State management for the waiver content
  const [wid, setWid] = useState()
  const [waivers, setWaivers] = useState()

  // Handle content change in the editor
  const handleContentChange = content => {
    setWaivers(content)
  }

  const { waiver, updateWaiverSuccess, updateWaiverError } = useSelector(
    state => ({
      waiver: state.waiver.waiver,
      updateWaiverSuccess: state.waiver.updateWaiverSuccess,
      updateWaiverError: state.waiver.updateWaiverError,
    }),
  )

  useEffect(() => {
    dispatch(getWaiver())
  }, [dispatch])

  useEffect(() => {
    if (waiver) {
      setWid(waiver.id)
      setWaivers(waiver.description)
    }
  }, [waiver])

  useEffect(() => {
    if (updateWaiverSuccess || updateWaiverError) {
      const timer = setTimeout(() => {
        dispatch(resetWaiverFlag())
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [updateWaiverSuccess, updateWaiverError, dispatch])

  // React Quill modules for toolbar customization
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  }

  return (
    <>
      {/* Content Section */}
      {updateWaiverError && (
        <Alert
          color="danger"
          style={{
            position: "fixed",
            top: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
          }}
        >
          {JSON.stringify(updateWaiverError)}
        </Alert>
      )}
      {updateWaiverSuccess && (
        <Alert
          color="success"
          style={{
            position: "fixed",
            top: "80px",
            right: "10px",
            zIndex: 1000,
          }}
        >
          {updateWaiverSuccess}
        </Alert>
      )}
      <div className="page-content">
        <div className="container-fluid">
          <h3 className="mb-4">Manage Waiver</h3>

          {/* React Quill Editor */}
          <ReactQuill
            value={waivers}
            onChange={handleContentChange}
            modules={modules}
            // theme="snow"
            className="bg-white p-4 rounded-md shadow-md"
            style={{ height: "500px" }}
          />

          <div style={{ textAlign: "end" }}>
            <button
              onClick={() => {
                const payload = {
                  id: wid,
                  description: waivers,
                }
                if (waiver?.description === "") {
                  dispatch(createWaiver(payload))
                } else {
                  dispatch(updateWaiver(payload))
                }
              }}
              style={{
                background: "#556ee6",
                width: "144px",
                height: "44px",
                border: "0",
                fontSize: "16px",
                borderRadius: "26px",
                margin: "32px 25px",
                color: "#fff",
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ManageWaiver
