import React from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import Switch from "react-switch"

const EventCost = cell => {
  return cell.value || cell.value === 0 ? `$${cell.value}` : ""
}

const OrderId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell.value ? cell.value : ""}
    </Link>
  )
}

const CourseUrl = cell => {
  return (
    <a
      href={`${cell.value}?courseUrl=${cell.value}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {cell.value ? cell.value : ""}
    </a>
  )
}

const ProfileUrl = cell => {
  return (
    <a
      href={`${process.env.REACT_APP_FRONTEND_URL}/instructor-profile?userId=${cell.value.id}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {cell.value ? cell.value.username : ""}
    </a>
  )
}

const CommonField = cell => {
  return cell.value ? cell.value : ""
}

const SwitchField = cell => {
  return <Switch checked={cell.value} onChange={cell.onChange} />
}

const EventName = cell => {
  return cell.value ? cell.value : ""
}

const Date = cell => {
  return cell.value ? moment(cell.value).format("DD MMM y") : ""
}

export {
  OrderId,
  EventName,
  Date,
  CommonField,
  CourseUrl,
  ProfileUrl,
  SwitchField,
  EventCost,
}
