import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, Alert, CardBody } from "reactstrap"

import {
  getSmtp,
  updateSmtp,
  addSmtp,
  addStripe,
  resetSmtpAlert,
  getStripe,
  updateStripe,
  resetStripeAlert,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"
import Form from "react-bootstrap/Form"
// actions

const AdminSetting = () => {
  //meta title
  document.title = "Settings | Tacticulture - Admin"

  const dispatch = useDispatch()

  // States for SMTP
  const [id, setId] = useState(null)
  const [host, setHost] = useState(0)
  const [port, setPort] = useState("")
  const [userName, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")

  // States for Stripe
  const [stripeId, setStripeId] = useState("")
  const [publishKey, setPublishKey] = useState("")
  const [secretKey, setSecretKey] = useState("")
  const [productId, setProductId] = useState("")
  const [webhookSecret, setWebhookSecret] = useState("")

  const {
    smtp,
    stripe,
    updateSmtpError,
    updateStripeError,
    updateSmtpSuccess,
    updateStripeSuccess,
    addSmtpSuccess,
    addStripeSuccess,
    addSmtpError,
    addStripeError,
  } = useSelector(state => ({
    smtp: state.adminSetting.smtp,
    stripe: state.adminSetting.stripe,
    updateSmtpError: state.adminSetting.updateSmtpError?.response?.data,
    updateStripeError: state.adminSetting.updateStripeError?.response?.data,
    updateSmtpSuccess: state.adminSetting.updateSmtpSuccess,
    updateStripeSuccess: state.adminSetting.updateStripeSuccess,
    addSmtpSuccess: state.adminSetting.addSmtpSuccess,
    addStripeSuccess: state.adminSetting.addStripeSuccess,
    addSmtpError: state.adminSetting.addSmtpError?.response?.data,
    addStripeError: state.adminSetting.addStripeError?.response?.data,
  }))

  useEffect(() => {
    dispatch(getSmtp())
    dispatch(getStripe())
  }, [dispatch])

  useEffect(() => {
    if (
      updateSmtpError ||
      updateSmtpSuccess ||
      addSmtpError ||
      addSmtpSuccess ||
      updateStripeError ||
      updateStripeSuccess ||
      addStripeError ||
      addStripeSuccess
    ) {
      const timer = setTimeout(() => {
        dispatch(resetSmtpAlert())
        dispatch(resetStripeAlert())
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [
    updateSmtpError,
    updateSmtpSuccess,
    addSmtpError,
    addSmtpSuccess,
    updateStripeError,
    updateStripeSuccess,
    addStripeError,
    addStripeSuccess,
    dispatch,
  ])

  useEffect(() => {
    if (smtp) {
      setId(smtp.id || null)
      setHost(smtp.smtp_host || "")
      setPort(smtp.smtp_port || "")
      setUsername(smtp.smtp_username || "")
      setPassword(smtp.smtp_password || "")
      setEmail(smtp.default_email || "")
    }
  }, [smtp])

  useEffect(() => {
    if (stripe) {
      setStripeId(stripe.id || null)
      setPublishKey(stripe.publishable_key || "")
      setSecretKey(stripe.secret_key || "")
      setProductId(stripe.product_id || "")
      setWebhookSecret(stripe.webhook_secret || "")
    }
  }, [stripe])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              {(updateSmtpError || updateStripeError) && (
                <Alert
                  color="danger"
                  style={{
                    position: "fixed",
                    top: "20px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 1000,
                  }}
                >
                  {JSON.stringify(
                    updateSmtpError ? updateSmtpError : updateStripeError,
                  )}
                </Alert>
              )}
              {(addSmtpError || addStripeError) && (
                <Alert
                  color="danger"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {JSON.stringify(addSmtpError ? addSmtpError : addStripeError)}
                </Alert>
              )}
              {(updateSmtpSuccess || updateStripeSuccess) && (
                <Alert
                  color="success"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {updateSmtpSuccess ? updateSmtpSuccess : updateStripeSuccess}
                </Alert>
              )}
              {(addSmtpSuccess || addStripeSuccess) && (
                <Alert
                  color="success"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {addSmtpSuccess ? addSmtpSuccess : addStripeSuccess}
                </Alert>
              )}
              <div className="">
                <h3
                  style={{
                    fontSize: "20px",
                    borderBottom: "1px solid #000",
                    paddingBottom: "9px",
                    margin: "18px 0",
                  }}
                >
                  Admin Settings
                </h3>
              </div>

              <Card>
                <div className="">
                  <h3
                    style={{
                      fontSize: "18px",
                      borderBottom: "1px solid #000",
                      paddingBottom: "9px",
                      margin: "18px 18px 0",
                    }}
                  >
                    Manage SMTP Details
                  </h3>
                </div>
                <CardBody>
                  <div className="container-fluid p-0">
                    <div className="row fieldDetails">
                      <div className="col-md-12">
                        <Form.Label>SMTP HOST</Form.Label>
                        <Form.Control
                          type="text"
                          value={host}
                          onChange={e => setHost(e.target.value)}
                          placeholder="Enter Your SMTP Host"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>SMTP PORT</Form.Label>
                        <Form.Control
                          type="number"
                          value={port}
                          onChange={e => setPort(e.target.value)}
                          placeholder="Enter Your SMTP Port"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>SMTP User Name</Form.Label>
                        <Form.Control
                          type="text"
                          value={userName}
                          onChange={e => setUsername(e.target.value)}
                          placeholder="Enter Your SMTP User Name"
                        />
                      </div>

                      <div className="col-md-12">
                        <Form.Label>SMTP Email</Form.Label>
                        <Form.Control
                          type="text"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          placeholder="Enter Your SMTP User Name"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>SMTP Password</Form.Label>
                        <Form.Control
                          type="password"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          placeholder="Enter your SMTP Password"
                        />
                      </div>

                      <div style={{ textAlign: "end" }}>
                        <button
                          onClick={() => {
                            const payload = {
                              id: id,
                              default_email: email,
                              smtp_host: host,
                              smtp_port: port,
                              smtp_username: userName,
                              smtp_password: password,
                              use_tls: true,
                              use_ssl: true,
                            }
                            if (smtp === undefined) {
                              dispatch(addSmtp(payload))
                            } else {
                              dispatch(updateSmtp(payload))
                            }
                          }}
                          style={{
                            background: "#556ee6",
                            width: "144px",
                            height: "44px",
                            border: "0",
                            fontSize: "16px",
                            borderRadius: "26px",
                            margin: "18px 0",
                            color: "#fff",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <div className="">
                  <h3
                    style={{
                      fontSize: "18px",
                      borderBottom: "1px solid #000",
                      paddingBottom: "9px",
                      margin: "18px 18px 0",
                    }}
                  >
                    Manage Stripe Details
                  </h3>
                </div>
                <CardBody>
                  <div className="container-fluid p-0">
                    <div className="row fieldDetails">
                      <div className="col-md-12">
                        <Form.Label>Stripe Product Id</Form.Label>
                        <Form.Control
                          type="text"
                          value={productId}
                          onChange={e => setProductId(e.target.value)}
                          placeholder="Enter Your Secret Key"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Stripe Secret Key</Form.Label>
                        <Form.Control
                          type="text"
                          value={secretKey}
                          onChange={e => setSecretKey(e.target.value)}
                          placeholder="Enter Your Secret Key"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Stripe Published Key</Form.Label>
                        <Form.Control
                          type="text"
                          value={publishKey}
                          onChange={e => setPublishKey(e.target.value)}
                          placeholder="Enter Your Published Key"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Stripe Webhook Secret</Form.Label>
                        <Form.Control
                          type="text"
                          value={webhookSecret}
                          onChange={e => setWebhookSecret(e.target.value)}
                          placeholder="Enter Your Manage Webhook Key"
                        />
                      </div>

                      <div style={{ textAlign: "end" }}>
                        <button
                          onClick={() => {
                            const payload = {
                              id: stripeId,
                              publishable_key: publishKey,
                              secret_key: secretKey,
                              product_id: productId,
                              webhook_secret: webhookSecret,
                            }
                            if (stripe === undefined) {
                              dispatch(addStripe(payload))
                            } else {
                              dispatch(updateStripe(payload))
                            }
                          }}
                          style={{
                            background: "#556ee6",
                            width: "144px",
                            height: "44px",
                            border: "0",
                            fontSize: "16px",
                            borderRadius: "26px",
                            margin: "18px 0",
                            color: "#fff",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AdminSetting)
