import { call, put, takeEvery } from "redux-saga/effects"

import { GET_PROFILE, UPDATE_PROFILE } from "./actionTypes"
import {
  getProfileSuccess,
  getProfileFail,
  updateProfileSuccess,
  updateProfileFail,
} from "./actions"

import { showLoader, hideLoader } from "store/actions"

import { get, putt } from "helpers/api_helper"

function* fetchProfile() {
  try {
    yield put(showLoader())
    const response = yield call(get, "/api/v1/admin/users/profile/")

    yield put(getProfileSuccess(response))
  } catch (error) {
    yield put(getProfileFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* updateProfile({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      putt,
      `/api/v1/admin/users/profile/update/`,
      data,
    )
    yield put(updateProfileSuccess(response))
  } catch (error) {
    yield put(updateProfileFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* adminProfileSaga() {
  yield takeEvery(GET_PROFILE, fetchProfile)
  yield takeEvery(UPDATE_PROFILE, updateProfile)
}

export default adminProfileSaga
