import React, { useState, useEffect } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css" // Import Quill's styles
import {
  getPrivacyPolicy,
  updatePrivacyPolicy,
  createPrivacyPolicy,
  resetAlertFlag,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { Alert } from "reactstrap"

const PrivacyPolicy = () => {
  document.title = "Privacy Policy | Tacticulture-Admin"

  const dispatch = useDispatch()

  // State management for the privacy policy content
  const [pid, setPid] = useState()
  const [privacyPolicies, setPrivacyPolicies] = useState("")

  // Handle content change in the editor
  const handleContentChange = content => {
    setPrivacyPolicies(content)
  }

  const {
    privacyPolicy,
    updatePrivacyPolicySuccess,
    updatePrivacyPolicyError,
  } = useSelector(state => ({
    privacyPolicy: state.contents.privacyPolicy,
    updatePrivacyPolicySuccess: state.contents.updatePrivacyPolicySuccess,
    updatePrivacyPolicyError: state.contents.updatePrivacyPolicyError,
  }))

  useEffect(() => {
    dispatch(getPrivacyPolicy())
  }, [dispatch])

  useEffect(() => {
    if (privacyPolicy) {
      setPid(privacyPolicy.id)
      setPrivacyPolicies(privacyPolicy.policy)
    }
  }, [privacyPolicy])

  useEffect(() => {
    if (updatePrivacyPolicySuccess || updatePrivacyPolicyError) {
      const timer = setTimeout(() => {
        dispatch(resetAlertFlag())
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [updatePrivacyPolicySuccess, updatePrivacyPolicyError, dispatch])

  // React Quill modules for toolbar customization
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  }

  return (
    <>
      {/* Content Section */}
      {updatePrivacyPolicyError && (
        <Alert
          color="danger"
          style={{
            position: "fixed",
            top: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
          }}
        >
          {JSON.stringify(updatePrivacyPolicyError)}
        </Alert>
      )}
      {updatePrivacyPolicySuccess && (
        <Alert
          color="success"
          style={{
            position: "fixed",
            top: "80px",
            right: "10px",
            zIndex: 1000,
          }}
        >
          {updatePrivacyPolicySuccess}
        </Alert>
      )}
      <div className="page-content">
        <div className="container-fluid">
          <h3 className="mb-4">Privacy Policy</h3>

          {/* React Quill Editor */}
          <ReactQuill
            value={privacyPolicies}
            onChange={handleContentChange}
            modules={modules}
            // theme="snow"
            className="bg-white p-4 rounded-md shadow-md"
            style={{ height: "500px" }}
          />

          <div style={{ textAlign: "end" }}>
            <button
              onClick={() => {
                const payload = {
                  id: pid,
                  policy: privacyPolicies,
                }
                if (privacyPolicy === undefined) {
                  dispatch(createPrivacyPolicy(payload))
                } else {
                  dispatch(updatePrivacyPolicy(payload))
                }
              }}
              style={{
                background: "#556ee6",
                width: "144px",
                height: "44px",
                border: "0",
                fontSize: "16px",
                borderRadius: "26px",
                margin: "32px 25px",
                color: "#fff",
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy
