import {
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAIL,
  GET_EVENT_DETAIL_SUCCESS,
  GET_EVENT_DETAIL_FAIL,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  events: [],
  eventsError: {},
  eventDetail: {},
  eventDetailError: {},
  eventDeleteError: {},
}

const Events = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
      }
    case GET_EVENTS_FAIL:
      return {
        ...state,
        eventsError: action.payload,
      }
    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.map(el =>
          el.id === action.payload.eventId
            ? { ...el, is_event_active: action.payload.value }
            : el,
        ),
      }
    case UPDATE_EVENT_FAIL:
      return {
        ...state,
      }
    case GET_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        eventDetail: action.payload,
      }
    case GET_EVENT_DETAIL_FAIL:
      return {
        ...state,
        eventDetailError: action.payload,
      }
    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.filter(
          event => event.id.toString() !== action.payload.toString(),
        ),
      }
    case DELETE_EVENT_FAIL:
      return {
        ...state,
        eventDeleteError: action.payload,
      }

    default:
      return state
  }
}

export default Events
