import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_EVENTS,
  DELETE_EVENT,
  GET_EVENT_DETAIL,
  UPDATE_EVENT,
} from "./actionTypes"
import {
  getEventsSuccess,
  getEventsFail,
  getEventDetailSuccess,
  deleteEventSuccess,
  deleteEventFail,
  getEventDetailFail,
  updateEventSuccess,
  updateEventFail,
} from "./actions"
import { showLoader, hideLoader } from "store/actions"
import { get, del, putt } from "helpers/api_helper"

function* fetchEvents() {
  try {
    yield put(showLoader())
    const response = yield call(get, "/api/v1/admin/event/event-list/")

    yield put(getEventsSuccess(response.results))
  } catch (error) {
    yield put(getEventsFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* updateEvent({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      putt,
      `/api/v1/admin/event/change-events-status/${data.eventId}/`,
      { is_event_active: data.value },
    )
    yield put(updateEventSuccess(data))
  } catch (error) {
    yield put(updateEventFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* fetchEventDetail({ payload: event }) {
  try {
    yield put(showLoader())
    const response = yield call(
      get,
      `/api/v1/admin/event/manage-event/${event}`,
    )

    yield put(getEventDetailSuccess(response))
  } catch (error) {
    yield put(getEventDetailFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* onDeleteEvent({ payload: event }) {
  try {
    yield put(showLoader())
    const response = yield call(
      del,
      `/api/v1/admin/event/manage-event/${event}`,
    )

    yield put(deleteEventSuccess(event))
  } catch (error) {
    yield put(deleteEventFail(error))
  } finally {
    yield put(hideLoader())
  }
}
function* eventSaga() {
  yield takeEvery(GET_EVENTS, fetchEvents)
  yield takeEvery(GET_EVENT_DETAIL, fetchEventDetail)
  yield takeEvery(DELETE_EVENT, onDeleteEvent)
  yield takeEvery(UPDATE_EVENT, updateEvent)
}

export default eventSaga
