import {
  GET_WAIVER,
  GET_WAIVER_SUCCESS,
  GET_WAIVER_FAIL,
  CREATE_WAIVER,
  CREATE_WAIVER_SUCCESS,
  CREATE_WAIVER_FAIL,
  UPDATE_WAIVER,
  UPDATE_WAIVER_SUCCESS,
  UPDATE_WAIVER_FAIL,
  RESET_WAIVER_FLAG,
} from "./actionTypes"

export const getWaiver = () => ({
  type: GET_WAIVER,
})

export const getWaiverSuccess = data => ({
  type: GET_WAIVER_SUCCESS,
  payload: data,
})

export const getWaiverFail = error => ({
  type: GET_WAIVER_FAIL,
  payload: error,
})

export const createWaiver = value => ({
  type: CREATE_WAIVER,
  payload: value,
})

export const createWaiverSuccess = data => ({
  type: CREATE_WAIVER_SUCCESS,
  payload: data,
})

export const createWaiverFail = error => ({
  type: CREATE_WAIVER_FAIL,
  payload: error,
})

export const updateWaiver = value => ({
  type: UPDATE_WAIVER,
  payload: value,
})

export const updateWaiverSuccess = data => ({
  type: UPDATE_WAIVER_SUCCESS,
  payload: data,
})

export const updateWaiverFail = error => ({
  type: UPDATE_WAIVER_FAIL,
  payload: error,
})

export const resetWaiverFlag = () => ({
  type: RESET_WAIVER_FLAG,
})
