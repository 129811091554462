import React, { useState, useEffect } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css" // Import Quill's styles
import { useDispatch, useSelector } from "react-redux"
import {
  getTermsConditions,
  updateTermsConditions,
  createTermsConditions,
  resetAlertFlag,
} from "store/actions"
import { Alert } from "reactstrap"

const TermsConditions = () => {
  document.title = "Terms & Conditions | Tacticulture-Admin"
  const dispatch = useDispatch()
  // State management for the terms and conditions content
  const [tid, setTid] = useState()
  const [termsAndConditions, setTermsAndConditions] = useState("")

  // Handle content change in the editor
  const handleContentChange = content => {
    setTermsAndConditions(content)
  }

  const handleSave = () => {
    console.log("save button clicked")
  }

  const {
    termsConditions,
    updateTermsConditionsSuccess,
    updateTermsConditionsError,
  } = useSelector(state => ({
    termsConditions: state.contents.termsConditions,
    updateTermsConditionsSuccess: state.contents.updateTermsConditionsSuccess,
    updateTermsConditionsError: state.contents.updateTermsConditionsError,
  }))

  useEffect(() => {
    dispatch(getTermsConditions())
  }, [dispatch])

  useEffect(() => {
    if (termsConditions) {
      setTid(termsConditions.id)
      setTermsAndConditions(termsConditions.terms)
    }
  }, [termsConditions])

  useEffect(() => {
    if (updateTermsConditionsSuccess || updateTermsConditionsError) {
      const timer = setTimeout(() => {
        dispatch(resetAlertFlag())
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [updateTermsConditionsSuccess, updateTermsConditionsError, dispatch])

  // React Quill modules for toolbar customization
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  }

  return (
    <>
      {/* Content Section */}
      {updateTermsConditionsError && (
        <Alert
          color="danger"
          style={{
            position: "fixed",
            top: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
          }}
        >
          {JSON.stringify(updateTermsConditionsError)}
        </Alert>
      )}
      {updateTermsConditionsSuccess && (
        <Alert
          color="success"
          style={{
            position: "fixed",
            top: "80px",
            right: "10px",
            zIndex: 1000,
          }}
        >
          {updateTermsConditionsSuccess}
        </Alert>
      )}
      <div className="page-content">
        <div className="container-fluid">
          <h3 className="mb-4">Terms & Conditions</h3>

          {/* React Quill Editor */}
          <ReactQuill
            value={termsAndConditions}
            onChange={handleContentChange}
            modules={modules}
            // theme="snow"
            className="bg-white p-4 rounded-md shadow-md"
            style={{ height: "500px" }}
          />
          <div style={{ textAlign: "end" }}>
            <button
              onClick={() => {
                const payload = {
                  id: tid,
                  terms: termsAndConditions,
                }
                if (termsConditions === undefined) {
                  dispatch(createTermsConditions(payload))
                } else {
                  dispatch(updateTermsConditions(payload))
                }
              }}
              style={{
                background: "#556ee6",
                width: "144px",
                height: "44px",
                border: "0",
                fontSize: "16px",
                borderRadius: "26px",
                margin: "32px 25px",
                color: "#fff",
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default TermsConditions
