import React, { useEffect, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
import DeleteModal from "../../components/Common/DeleteModal"

import { OrderId, PlanCost, CommonField } from "./PlanCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap"

import { deletePlan, getPlans, getPlanDetail } from "store/plans/actions"

function Plans() {
  const navigate = useNavigate()
  //meta title
  document.title = "Plans | Tacticulture-Admin"
  const [delPlan, setDelPlan] = useState(null)

  const toggleViewModal = id => {
    dispatch(getPlanDetail(id))
    navigate(`/plan-detail?planId=${id}`)
  }

  const dispatch = useDispatch()
  const { plans } = useSelector(state => ({
    plans: state.Plans.plans,
  }))

  useEffect(() => {
    if (plans && !plans.length) {
      dispatch(getPlans())
    }
  }, [dispatch])

  //delete user
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = plan => {
    setDelPlan(plan)
    setDeleteModal(true)
  }

  const handleDeletePlan = () => {
    if (delPlan && delPlan.id) {
      dispatch(deletePlan(delPlan.id))
      setDeleteModal(false)
      setTimeout(() => {
        dispatch(getPlans())
      }, 1000)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Plan ID",
        accessor: "id",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Plan Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },
      {
        Header: "Plan Cost",
        accessor: "pricing",
        filterable: true,
        Cell: cellProps => {
          return <PlanCost {...cellProps} />
        },
      },
      {
        Header: "Plan Description",
        accessor: "description",
        filterable: true,
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },
      {
        Header: "Plan Duration",
        accessor: "plan_type",
        filterable: true,
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={e => {
                  e.preventDefault()
                  toggleViewModal(cellProps.row.original.id)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDelete(orderData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    [],
  )

  return (
    <React.Fragment>
      {/* <DeletePlanModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePlan}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <h3 className="mb-4">Manage Plans</h3>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-end">
                    <Button
                      color="primary"
                      onClick={() => navigate("/add-plan")}
                    >
                      Add New Plan
                    </Button>
                  </div>

                  <TableContainer
                    className="userTable"
                    columns={columns}
                    data={plans}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    forFilter={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Plans
