import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"
import eventSaga from "./events/saga"
import waiverSaga from "./waiver/saga"
import userSaga from "./users/saga"
import adminProfileSaga from "./profile/saga"
import adminSettingSaga from "./admin-settings/saga"
import bookedEventSaga from "./bookedEvent/saga"
import planSaga from "./plans/saga"
import userSubscriptionSaga from "./subscription/saga"
import siteInfoSaga from "./site-info/saga"
import dashboardSaga from "./dashboard/saga"
import purchaseEventSaga from "./purchase/saga"
import contentSaga from "./contents/saga"
import categorySaga from "./categories/saga"

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(eventSaga),
    fork(waiverSaga),
    fork(userSaga),
    fork(bookedEventSaga),
    fork(planSaga),
    fork(userSubscriptionSaga),
    fork(contentSaga),
    fork(categorySaga),
    fork(siteInfoSaga),
    fork(adminProfileSaga),
    fork(adminSettingSaga),
    fork(dashboardSaga),
    fork(purchaseEventSaga),
  ])
}
