/* Privacy Policy */
export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY"
export const GET_PRIVACY_POLICY_SUCCESS = "GET_PRIVACY_POLICY_SUCCESS"
export const GET_PRIVACY_POLICY_FAIL = "GET_PRIVACY_POLICY_FAIL"

export const CREATE_PRIVACY_POLICY = "CREATE_PRIVACY_POLICY"
export const CREATE_PRIVACY_POLICY_SUCCESS = "CREATE_PRIVACY_POLICY_SUCCESS"
export const CREATE_PRIVACY_POLICY_FAIL = "CREATE_PRIVACY_POLICY_FAIL"

export const UPDATE_PRIVACY_POLICY = "UPDATE_PRIVACY_POLICY"
export const UPDATE_PRIVACY_POLICY_SUCCESS = "UPDATE_PRIVACY_POLICY_SUCCESS"
export const UPDATE_PRIVACY_POLICY_FAIL = "UPDATE_PRIVACY_POLICY_FAIL"

/* Terms and Conditions */
export const GET_TERMS_CONDITIONS = "GET_TERMS_CONDITIONS"
export const GET_TERMS_CONDITIONS_SUCCESS = "GET_TERMS_CONDITIONS_SUCCESS"
export const GET_TERMS_CONDITIONS_FAIL = "GET_TERMS_CONDITIONS_FAIL"

export const CREATE_TERMS_CONDITIONS = "CREATE_TERMS_CONDITIONS"
export const CREATE_TERMS_CONDITIONS_SUCCESS = "CREATE_TERMS_CONDITIONS_SUCCESS"
export const CREATE_TERMS_CONDITIONS_FAIL = "CREATE_TERMS_CONDITIONS_FAIL"

export const UPDATE_TERMS_CONDITIONS = "UPDATE_TERMS_CONDITIONS"
export const UPDATE_TERMS_CONDITIONS_SUCCESS = "UPDATE_TERMS_CONDITIONS_SUCCESS"
export const UPDATE_TERMS_CONDITIONS_FAIL = "UPDATE_TERMS_CONDITIONS_FAIL"

/* Reset Alert flag*/
export const RESET_ALERT_FLAG = "RESET_ALERT_FLAG"
