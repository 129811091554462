import { call, put, takeEvery } from "redux-saga/effects"

// admin settings Redux States
import {
  GET_PRIVACY_POLICY,
  GET_TERMS_CONDITIONS,
  UPDATE_PRIVACY_POLICY,
  UPDATE_TERMS_CONDITIONS,
  CREATE_PRIVACY_POLICY,
  CREATE_TERMS_CONDITIONS,
} from "./actionTypes"
import {
  getPrivacyPolicySuccess,
  getTermsConditionsSuccess,
  getPrivacyPolicyFail,
  getTermsConditionsFail,
  updatePrivacyPolicySuccess,
  updateTermsConditionsSuccess,
  updatePrivacyPolicyFail,
  updateTermsConditionsFail,
  createPrivacyPolicySuccess,
  createTermsConditionsSuccess,
  createPrivacyPolicyFail,
  createTermsConditionsFail,
} from "./actions"

import { showLoader, hideLoader } from "store/actions"

import { get, putt, post } from "helpers/api_helper"

function* fetchPrivacyPolicy() {
  try {
    yield put(showLoader())
    const response = yield call(get, "/api/v1/site-settings/policies/")

    yield put(getPrivacyPolicySuccess(response.results[0]))
  } catch (error) {
    yield put(getPrivacyPolicyFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* fetchTermsConditions() {
  try {
    yield put(showLoader())
    const response = yield call(
      get,
      "/api/v1/site-settings/terms-and-conditions/",
    )

    yield put(getTermsConditionsSuccess(response.results[0]))
  } catch (error) {
    yield put(getTermsConditionsFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* updatePrivacyPolicy({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      putt,
      `/api/v1/site-settings/policies/${data.id}/`,
      data,
    )
    // yield put(updateProfileSuccess(data))
    yield put(updatePrivacyPolicySuccess(response))
  } catch (error) {
    yield put(updatePrivacyPolicyFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* updateTermsConditions({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      putt,
      `/api/v1/site-settings/terms-and-conditions/${data.id}/`,
      data,
    )
    yield put(updateTermsConditionsSuccess(response))
  } catch (error) {
    yield put(updateTermsConditionsFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* createPrivacyPolicy({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(post, `/api/v1/site-settings/policies/`, data)
    // yield put(updateProfileSuccess(data))
    yield put(createPrivacyPolicySuccess(response))
  } catch (error) {
    yield put(createPrivacyPolicyFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* createTermsConditions({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      post,
      `/api/v1/site-settings/terms-and-conditions/`,
      data,
    )
    // yield put(updateProfileSuccess(data))
    yield put(createTermsConditionsSuccess(response))
  } catch (error) {
    yield put(createTermsConditionsFail(error))
  } finally {
    yield put(hideLoader())
  }
}
function* contentSaga() {
  yield takeEvery(GET_PRIVACY_POLICY, fetchPrivacyPolicy)
  yield takeEvery(UPDATE_PRIVACY_POLICY, updatePrivacyPolicy)
  yield takeEvery(CREATE_PRIVACY_POLICY, createPrivacyPolicy)

  yield takeEvery(GET_TERMS_CONDITIONS, fetchTermsConditions)
  yield takeEvery(UPDATE_TERMS_CONDITIONS, updateTermsConditions)
  yield takeEvery(CREATE_TERMS_CONDITIONS, createTermsConditions)
}

export default contentSaga
