import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"

// Events
import events from "./events/reducer"

//waiver
import waiver from "./waiver/reducer"

// Users
import users from "./users/reducer"

// Admin Profile
import adminProfile from "./profile/reducer"

// Admin Settings
import adminSetting from "./admin-settings/reducer"

// Booked Event
import bookedEvents from "./bookedEvent/reducer"

// plans
import Plans from "./plans/reducer"

// user subscriptions
import UsersSubscriptions from "./subscription/reducer"

//loader
import loaderReducer from "./loader/reducer"

// Dashboard
import dashboard from "./dashboard/reducer"

// contents
import contents from "./contents/reducer"

//category
import Categories from "./categories/reducer"

//site info
import siteInfo from "./site-info/reducer"

// Purchase
import Purchase from "./purchase/reducer"

const rootReducer = combineReducers({
  Layout,
  Login,
  adminProfile,
  adminSetting,
  events,
  waiver,
  bookedEvents,
  Plans,
  UsersSubscriptions,
  users,
  contents,
  Categories,
  siteInfo,
  loaderReducer,
  dashboard,
  Purchase,
})

export default rootReducer
