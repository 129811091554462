import { call, put, takeEvery } from "redux-saga/effects"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"
import { post } from "helpers/api_helper"
import { showLoader, hideLoader } from "store/actions"

function* loginUser({ payload: { user, history } }) {
  try {
    yield put(showLoader())
    const response = yield call(post, "/api/v1/admin/users/login/", {
      email: user.email,
      password: user.password,
    })

    if (response.accessToken) {
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response, "User Login Successfully."))
      yield call(history, "/dashboard")
    } else {
      yield put(loginSuccess(response, "Wrong Credentials"))
    }
  } catch (error) {
    yield put(apiError(error))
  } finally {
    yield put(hideLoader())
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}
function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
