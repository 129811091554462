import React, { useEffect, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  deleteEvent,
  getEventDetail,
  getEvents,
  updateEvent,
} from "store/actions"

import {
  OrderId,
  EventName,
  Date as EventDate,
  CommonField,
  CourseUrl,
  ProfileUrl,
  SwitchField,
  EventCost,
} from "./EventsCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap"

function Events() {
  const navigate = useNavigate()
  //meta title
  document.title = "Events | Tacticulture-Admin"
  const [event, setEvent] = useState(null)
  const [switchValue, setSwitchValue] = useState("")

  const toggleViewModal = id => {
    dispatch(getEventDetail(id))
    navigate(`/event-detail?eventId=${id}`)
  }

  const dispatch = useDispatch()
  const { events } = useSelector(state => ({
    events: state.events.events,
  }))

  useEffect(() => {
    if (events && !events.length) {
      dispatch(getEvents())
    }
  }, [dispatch])

  const sortedEvents = useMemo(() => {
    return events.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at),
    )
  }, [events])

  //delete event
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = event => {
    setEvent(event)
    setDeleteModal(true)
  }

  const handleDeleteEvent = () => {
    if (event && event.id) {
      dispatch(deleteEvent(event.id))
      setDeleteModal(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Event ID",
        accessor: "id",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Event Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <EventName {...cellProps} />
        },
      },
      {
        Header: "Instructor",
        accessor: "created_by",
        filterable: true,
        Cell: cellProps => {
          return <ProfileUrl {...cellProps} />
        },
      },
      {
        Header: "Created Date",
        accessor: "created_at",
        filterable: true,
        Cell: cellProps => {
          return <EventDate {...cellProps} />
        },
      },
      {
        Header: "Course Url",
        accessor: "course_url",
        filterable: true,
        Cell: cellProps => {
          return <CourseUrl {...cellProps} />
        },
      },
      {
        Header: "Event Status",
        accessor: "is_event_active",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <SwitchField
                {...cellProps}
                onChange={value => {
                  dispatch(updateEvent(cellProps.row.original.id, value))
                  setSwitchValue(value)
                }}
              />
            </>
          )
        },
      },
      {
        Header: "Event Cost",
        accessor: "cost_per_spot",
        filterable: true,
        Cell: cellProps => {
          return <EventCost {...cellProps} />
        },
      },
      {
        Header: "Available Spots",
        accessor: "available_spots",
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },
      {
        Header: "View Details",
        accessor: "view",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => toggleViewModal(cellProps.row.original.id)}
            >
              View Details
            </Button>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDelete(orderData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    [],
  )

  const handleRowClick = row => {
    dispatch(getEventDetail(row.original.id))
    navigate(`/event-detail?eventId=${row.original.id}`) // Navigate to event detail page based on event id
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <h3 className="mb-4">Manage Events</h3>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={sortedEvents}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    getRowProps={row => ({
                      onClick: e => {
                        if (
                          !e.target.closest("[data-column='course_url']") &&
                          !e.target.closest("[data-column='status']") &&
                          !e.target.closest("[data-column='action']") &&
                          !e.target.closest("[data-column='created_by']")
                        ) {
                          handleRowClick(row)
                        }
                      },
                      style: { cursor: "pointer" }, // Make cursor pointer for rows
                    })}
                    customPageSize={10}
                    forFilter={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Events
