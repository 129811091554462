import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_CATEGORIES,
  ADD_CATEGORY,
  DELETE_CATEGORY,
  GET_CATEGORY_DETAIL,
  UPDATE_CATEGORY,
} from "./actionTypes"
import {
  addCategorySuccess,
  addCategoryFail,
  getCategoriesSuccess,
  getCategoriesFail,
  getCategoryDetailSuccess,
  deleteCategorySuccess,
  deleteCategoryFail,
  getCategoryDetailFail,
  updateCategorySuccess,
  updateCategoryFail,
} from "./actions"

import { showLoader, hideLoader } from "store/actions"

//Include Both Helper File with needed methods
import {
  getEventFullDetail,
  getEventsFullData,
} from "helpers/fakebackend_helper"
import { del, get, putt, post } from "helpers/api_helper"

function* fetchCategories() {
  try {
    yield put(showLoader())
    const response = yield call(get, "/api/v1/admin/event/event-categories/")

    yield put(getCategoriesSuccess(response.results))
  } catch (error) {
    yield put(getCategoriesFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* updateCategory({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      putt,
      `/api/v1/admin/event/event-categories/${data.id}/`,
      data,
    )
    yield put(updateCategorySuccess(response))
  } catch (error) {
    yield put(updateCategoryFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* fetchCategoryDetail({ payload: id }) {
  try {
    yield put(showLoader())
    const response = yield call(
      get,
      `/api/v1/admin/event/event-categories/${id}/`,
    )

    yield put(getCategoryDetailSuccess(response))
  } catch (error) {
    yield put(getCategoryDetailFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* onDeleteCategory({ payload: id }) {
  try {
    yield put(showLoader())
    const response = yield call(
      del,
      `/api/v1/admin/event/event-categories/${id}/`,
    )
    yield put(deleteCategorySuccess(response))
  } catch (error) {
    yield put(deleteCategoryFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* addCategory({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      post,
      "/api/v1/admin/event/event-categories/",
      data,
    )
    yield put(addCategorySuccess(response))
  } catch (error) {
    yield put(addCategoryFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* categorySaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories)
  yield takeEvery(GET_CATEGORY_DETAIL, fetchCategoryDetail)
  yield takeEvery(DELETE_CATEGORY, onDeleteCategory)
  yield takeEvery(UPDATE_CATEGORY, updateCategory)
  yield takeEvery(ADD_CATEGORY, addCategory)
}

export default categorySaga
