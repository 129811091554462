import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, Alert, CardBody } from "reactstrap"
import Lefts from "../../assets/images/lefts.png"
// Formik Validation
import { getProfile, updateProfile } from "store/actions"
//redux
import { useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"
import Form from "react-bootstrap/Form"
import UserLogo from "../../assets/images/userLogo.png"
// actions
import { resetProfileAlert } from "../../store/actions"
import { useNavigate } from "react-router-dom"

const UserProfile = () => {
  //meta title
  document.title = "Profile | Tacticulture - Admin"

  const dispatch = useDispatch()

  const [idx, setidx] = useState(0)
  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")

  const navigate = useNavigate()

  const { profile, updateProfileError, updateProfileSuccess } = useSelector(
    state => ({
      profile: state.adminProfile.profile,
      updateProfileError: state.adminProfile.updateProfileError?.response?.data,
      updateProfileSuccess: state.adminProfile.updateProfileSuccess,
    }),
  )

  useEffect(() => {
    dispatch(getProfile())
  }, [dispatch])

  useEffect(() => {
    if (updateProfileError || updateProfileSuccess) {
      const timer = setTimeout(() => {
        dispatch(resetProfileAlert())
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [updateProfileError, updateProfileSuccess, dispatch])

  useEffect(() => {
    if (profile) {
      setidx(profile.id || "")
      setname(profile.first_name || "")
      setemail(profile.email || "")
    }
  }, [profile])

  return (
    <React.Fragment>
      {" "}
      <button
        onClick={() => navigate(-1)}
        style={{
          position: "relative",
          top: "95px",
          left: "21px",
          marginBottom: "120px",
          background: "#556ee6",
          width: "74px",
          height: "36px",
          border: "0",
          color: "#fff",
          fontSize: "16px",
          display: "flex",
          alignItems: "center",
          borderRadius: "8px",
        }}
      >
        <img
          src={Lefts}
          alt=""
          height="17"
          style={{ transform: "rotate(180deg)" }}
        />{" "}
        Back
      </button>
      <div className="page-content pt-0">
        <Container fluid>
          <Row>
            <Col lg="12">
              {updateProfileError && typeof updateProfileError === "object" ? (
                <Alert
                  color="danger"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {Object.keys(updateProfileError).map(key => (
                    <div key={key}>
                      {key}: {updateProfileError[key].join(", ")}{" "}
                    </div>
                  ))}
                </Alert>
              ) : (
                updateProfileError && (
                  <Alert
                    color="danger"
                    style={{
                      position: "fixed",
                      top: "80px",
                      right: "10px",
                      zIndex: 1000,
                    }}
                  >
                    {updateProfileError}
                  </Alert>
                )
              )}
              {updateProfileSuccess ? (
                <Alert
                  color="success"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {updateProfileSuccess}
                </Alert>
              ) : null}
              <div className="">
                <h3
                  style={{
                    fontSize: "18px",
                    borderBottom: "1px solid #000",
                    paddingBottom: "9px",
                    marginBottom: "20px",
                    marginTop: "30px",
                  }}
                >
                  Profile
                </h3>
              </div>
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={UserLogo}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{profile.first_name}</h5>
                        <p className="mb-1">{profile.email}</p>
                        <p className="mb-0">Id no: #{idx}</p>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0">
                    <div className="row fieldDetails">
                      <div className="col-md-12">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          value={name}
                          onChange={e => setname(e.target.value)}
                          placeholder="Enter Your Name"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          type="email"
                          value={email}
                          onChange={e => setemail(e.target.value)}
                          placeholder="Enter Your Email"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                          type="password"
                          value={oldPassword}
                          onChange={e => setOldPassword(e.target.value)}
                          placeholder="Enter Your Current Password"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          value={newPassword}
                          onChange={e => setNewPassword(e.target.value)}
                          placeholder="Enter New Password"
                        />
                      </div>
                      <div style={{ textAlign: "end" }}>
                        <button
                          onClick={() => {
                            const payload = {
                              first_name: name,
                              email: email,
                            }

                            if (oldPassword !== "" && newPassword !== "") {
                              payload.current_password = oldPassword
                              payload.new_password = newPassword
                            }
                            dispatch(updateProfile(payload))
                          }}
                          style={{
                            background: "#556ee6",
                            width: "144px",
                            height: "44px",
                            border: "0",
                            fontSize: "16px",
                            borderRadius: "26px",
                            margin: "18px 0",
                            color: "#fff",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
