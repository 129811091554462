import { call, put, takeEvery } from "redux-saga/effects"

import { GET_USER_SUBSCRIPTIONS } from "./actionTypes"
import { getUserSubscriptionSuccess, getUserSubscriptionFail } from "./actions"
import { showLoader, hideLoader } from "store/actions"

import { get } from "helpers/api_helper"

function* fetchUserSubscriptions() {
  try {
    yield put(showLoader())
    const response = yield call(
      get,
      "/api/v1/admin/users/subscriptions/records/",
    )
    yield put(getUserSubscriptionSuccess(response.results))
  } catch (error) {
    yield put(getUserSubscriptionFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* userSubscriptionSaga() {
  yield takeEvery(GET_USER_SUBSCRIPTIONS, fetchUserSubscriptions)
}

export default userSubscriptionSaga
