import { call, put, takeEvery } from "redux-saga/effects"

// manage waiver Redux States
import { GET_WAIVER, UPDATE_WAIVER, CREATE_WAIVER } from "./actionTypes"
import {
  getWaiverSuccess,
  getWaiverFail,
  updateWaiverSuccess,
  updateWaiverFail,
  createWaiverSuccess,
  createWaiverFail,
} from "./actions"

import { showLoader, hideLoader } from "store/actions"

import { get, putt, post } from "helpers/api_helper"

function* fetchWaiver() {
  try {
    yield put(showLoader())
    const response = yield call(
      get,
      "/api/v1/site-settings/manage-waiver-settings/",
    )
    yield put(getWaiverSuccess(response))
  } catch (error) {
    yield put(getWaiverFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* updateWaiver({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      putt,
      `/api/v1/site-settings/manage-waiver-settings/`,
      data,
    )
    yield put(updateWaiverSuccess(response))
  } catch (error) {
    yield put(updateWaiverFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* createWaiver({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      post,
      `/api/v1/site-settings/manage-waiver-settings/`,
      data,
    )
    yield put(createWaiverSuccess(response))
  } catch (error) {
    yield put(createWaiverFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* waiverSaga() {
  yield takeEvery(GET_WAIVER, fetchWaiver)
  yield takeEvery(UPDATE_WAIVER, updateWaiver)
  yield takeEvery(CREATE_WAIVER, createWaiver)
}

export default waiverSaga
