import React from "react"
import { Link } from "react-router-dom"

const OrderId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell.value ? cell.value : ""}
    </Link>
  )
}

const CommonField = cell => {
  return cell.value ? cell.value : ""
}
export { OrderId, CommonField }
