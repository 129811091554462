import React, { useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

import {
  OrderId,
  EventName,
  Date as EventDate,
  InstructorDetails,
  ApprenticeDetails,
  BookingAmount,
} from "./BookedCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Col, Row, UncontrolledTooltip, Card, CardBody } from "reactstrap"
import { getBookedEvent } from "store/bookedEvent/actions"

function Booked() {
  //meta title
  document.title = "Booked-Event | Tacticulture-Admin"

  const dispatch = useDispatch()
  const { bookedEvents } = useSelector(state => ({
    bookedEvents: state.bookedEvents.bookedEvents,
  }))

  useEffect(() => {
    if (bookedEvents && !bookedEvents.length) {
      dispatch(getBookedEvent())
    }
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: "Order Date",
        accessor: "created_at",
        filterable: true,
        Cell: cellProps => {
          return <EventDate {...cellProps} />
        },
      },
      {
        Header: "Order ID",
        accessor: "order_id",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Transaction ID",
        accessor: "transaction_id",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Amount",
        accessor: "booking_amount",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <BookingAmount {...cellProps} />
        },
      },
      {
        Header: "Event Name",
        accessor: "event",
        filterable: true,
        Cell: cellProps => {
          return (
            <div>
              <EventName {...cellProps} />
              <a
                href={`${cellProps.row.original.event_url}?courseUrl=${cellProps.row.original.event_url}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: "block" }}
              >
                event page
              </a>
            </div>
          )
        },
      },
      {
        Header: "Instructor",
        accessor: "instructor_details",
        filterable: true,
        Cell: cellProps => {
          return <InstructorDetails {...cellProps} />
        },
      },
      {
        Header: "Apprentice",
        accessor: "created_by",
        filterable: true,
        Cell: cellProps => {
          return <ApprenticeDetails {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={cellProps.row.original.invoice}
                className="text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="mdi mdi-file-download font-size-18"
                  id="downloadtooltip"
                />
                <UncontrolledTooltip placement="top" target="downloadtooltip">
                  Download Invoice
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    [],
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <h3 className="mb-4">Manage Booking</h3>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={bookedEvents}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    forFilter={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Booked
