import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Events
import Events from "../pages/Events/index"

//manage waiver
import ManageWaiver from "pages/Waiver/index"

//Users
import Users from "../pages/User/index"

//Booked-Event
import Booked from "../pages/Booked-event/index"

//Settings
import SiteSetting from "pages/Site-settings/index"

//privacy policy
import PrivacyPolicy from "../pages/PrivacyPolicy/index"

//terms and conditions
import TermsConditions from "../pages/TermsConditions/index"

// detail pages
import EventDetails from "pages/Events/EventDetail"
import UserDetails from "pages/User/UserDetail"

// manage purchase
import Purchase from "pages/Purchase"
import PurchaseApprentice from "pages/Purchase/PurchaseApprentice"

//admin settintgs
import AdminSetting from "pages/Authentication/admin-setting"

//Plans
import Plans from "pages/Plans"
import PlanDetails from "pages/Plans/PlanDetail"
import AddPlan from "pages/Plans/addPlan"

//categories
import Categories from "pages/Categories"
import CategoryDetails from "pages/Categories/CategoryDetail"
import AddCategory from "pages/Categories/addCategory"

//Subscription Orders
import SubscriptionOrders from "pages/Subscription-orders"

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },

  //Events
  { path: "/events", component: <Events /> },
  { path: "/event-detail", component: <EventDetails /> },

  //manage waiver
  { path: "/manage-waiver-setting", component: <ManageWaiver /> },

  //Users
  { path: "/users", component: <Users /> },
  { path: "/user-detail", component: <UserDetails /> },

  //Booked-event
  { path: "/booked-event", component: <Booked /> },

  //privacy-policy
  { path: "/privacy-policy", component: <PrivacyPolicy /> },

  //terms and conditions
  { path: "/terms-conditions", component: <TermsConditions /> },

  //Purchase
  { path: "/purchase", component: <Purchase /> },
  { path: "/purchase-apprentice", component: <PurchaseApprentice /> },

  //Plans
  { path: "/plans", component: <Plans /> },
  { path: "/plan-detail", component: <PlanDetails /> },
  { path: "/add-plan", component: <AddPlan /> },

  //Categories
  { path: "/categories", component: <Categories /> },
  { path: "/category-detail", component: <CategoryDetails /> },
  { path: "/add-category", component: <AddCategory /> },

  //Subscription orders
  { path: "/subscription-orders", component: <SubscriptionOrders /> },

  //Site Settings
  { path: "/site-setting", component: <SiteSetting /> },

  //profile
  { path: "/profile", component: <UserProfile /> },
  { path: "/admin-setting", component: <AdminSetting /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
]

export { authProtectedRoutes, publicRoutes }
