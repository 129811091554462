import {
  GET_PRIVACY_POLICY_SUCCESS,
  GET_PRIVACY_POLICY_FAIL,
  GET_TERMS_CONDITIONS_SUCCESS,
  GET_TERMS_CONDITIONS_FAIL,
  CREATE_PRIVACY_POLICY_SUCCESS,
  CREATE_PRIVACY_POLICY_FAIL,
  CREATE_TERMS_CONDITIONS_SUCCESS,
  CREATE_TERMS_CONDITIONS_FAIL,
  UPDATE_PRIVACY_POLICY_SUCCESS,
  UPDATE_PRIVACY_POLICY_FAIL,
  UPDATE_TERMS_CONDITIONS_SUCCESS,
  UPDATE_TERMS_CONDITIONS_FAIL,
  RESET_ALERT_FLAG,
} from "./actionTypes"

const INIT_STATE = {
  privacyPolicy: {},
  termsConditions: {},
  privacyPolicyError: {},
  termsConditionsError: {},
  updatePrivacyPolicyError: null,
  updateTermsConditionsError: null,
  updatePrivacyPolicySuccess: null,
  updateTermsConditionsSuccess: null,
  addPrivacyPolicyError: null,
  addTermsConditionsError: null,
  addPrivacyPolicySuccess: null,
  addTermsConditionsSuccess: null,
}

const contents = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        privacyPolicy: action.payload,
        privacyPolicyError: null,
      }
    case GET_PRIVACY_POLICY_FAIL:
      return {
        ...state,
        privacyPolicyError: action.payload,
      }

    case GET_TERMS_CONDITIONS_SUCCESS:
      return {
        ...state,
        termsConditions: action.payload,
        termsConditionsError: null,
      }
    case GET_TERMS_CONDITIONS_FAIL:
      return {
        ...state,
        termsConditionsError: action.payload,
      }

    case UPDATE_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        privacyPolicy: action.payload,
        updatePrivacyPolicySuccess: "updated successfully",
        updatePrivacyPolicyError: null,
      }
    case UPDATE_PRIVACY_POLICY_FAIL:
      return {
        ...state,
        updatePrivacyPolicyError: action.payload,
        updatePrivacyPolicySuccess: null,
      }

    case UPDATE_TERMS_CONDITIONS_SUCCESS:
      return {
        ...state,
        termsConditions: action.payload,
        updateTermsConditionsSuccess: "updated successfully",
        updateTermsConditionsError: null,
      }
    case UPDATE_TERMS_CONDITIONS_FAIL:
      return {
        ...state,
        updateTermsConditionsError: action.payload,
        updateTermsConditionsSuccess: null,
      }

    case CREATE_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        privacyPolicy: action.payload,
        addPrivacyPolicySuccess: "updated successfully",
        addPrivacyPolicyError: null,
      }

    case CREATE_PRIVACY_POLICY_FAIL:
      return {
        ...state,
        addPrivacyPolicyError: action.payload,
        addPrivacyPolicySuccess: null,
      }

    case CREATE_TERMS_CONDITIONS_SUCCESS:
      return {
        ...state,
        termsConditions: action.payload,
        addTermsConditionsSuccess: "updated successfully",
        addTermsConditionsError: null,
      }

    case CREATE_TERMS_CONDITIONS_FAIL:
      return {
        ...state,
        addTermsConditionsError: action.payload,
        addTermsConditionsSuccess: null,
      }
    case RESET_ALERT_FLAG:
      return {
        ...state,
        updatePrivacyPolicySuccess: null,
        updatePrivacyPolicyError: null,
        addPrivacyPolicySuccess: null,
        addPrivacyPolicyError: null,

        updateTermsConditionsSuccess: null,
        updateTermsConditionsError: null,
        addTermsConditionsSuccess: null,
        addTermsConditionsError: null,
      }
    default:
      return state
  }
}

export default contents
