import {
  GET_WAIVER_SUCCESS,
  GET_WAIVER_FAIL,
  CREATE_WAIVER_SUCCESS,
  CREATE_WAIVER_FAIL,
  UPDATE_WAIVER_SUCCESS,
  UPDATE_WAIVER_FAIL,
  RESET_WAIVER_FLAG,
} from "./actionTypes"

const INIT_STATE = {
  waiver: {},
  waiverError: {},
  updateWaiverError: null,
  updateWaiverSuccess: null,
  addWaiverError: null,
  addWaiverSuccess: null,
}

const waiver = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WAIVER_SUCCESS:
      return {
        ...state,
        waiver: action.payload,
        waiverError: null,
      }
    case GET_WAIVER_FAIL:
      return {
        ...state,
        waiverError: action.payload,
      }

    case UPDATE_WAIVER_SUCCESS:
      return {
        ...state,
        waiver: action.payload,
        updateWaiverSuccess: "updated successfully",
        updateWaiverError: null,
      }
    case UPDATE_WAIVER_FAIL:
      return {
        ...state,
        updateWaiverError: action.payload,
        updateWaiverSuccess: null,
      }

    case CREATE_WAIVER_SUCCESS:
      return {
        ...state,
        waiver: action.payload,
        addWaiverSuccess: "updated successfully",
        addWaiverError: null,
      }

    case CREATE_WAIVER_FAIL:
      return {
        ...state,
        addWaiverError: action.payload,
        addWaiverSuccess: null,
      }
    case RESET_WAIVER_FLAG:
      return {
        ...state,
        updateWaiverSuccess: null,
        updateWaiverError: null,
        addWaiverSuccess: null,
        addWaiverError: null,

        updateWaiverSuccess: null,
        updateWaiverError: null,
        addWaiverSuccess: null,
        addWaiverError: null,
      }
    default:
      return state
  }
}

export default waiver
