import {
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_CATEGORY_DETAIL_SUCCESS,
  GET_CATEGORY_DETAIL_FAIL,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  RESET_ADD_CATEGORY_FLAG,
} from "./actionTypes"

const INIT_STATE = {
  categories: [],
  categoriesError: {},
  categoryDetail: {},
  categoryDetailError: {},
  categoryDeleteError: {},
  addCategoryError: null,
}

const Categories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      }
    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        categoriesError: action.payload,
      }

    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryDetail: action.payload,
      }
    case UPDATE_CATEGORY_FAIL:
      return {
        ...state,
      }
    case GET_CATEGORY_DETAIL_SUCCESS:
      return {
        ...state,
        categoryDetail: action.payload,
      }
    case GET_CATEGORY_DETAIL_FAIL:
      return {
        ...state,
        categoryDetailError: action.payload,
      }
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: state.categories.filter(
          category => category.id.toString() !== action.payload.toString(),
        ),
      }
    case DELETE_CATEGORY_FAIL:
      return {
        ...state,
        categoryDeleteError: action.payload,
      }

    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, action.payload],
        addCategoryError: null,
      }
    case ADD_CATEGORY_FAIL:
      return {
        ...state,
        addCategoryError: "Something Went Wrong",
      }

    case RESET_ADD_CATEGORY_FLAG:
      return {
        ...state,
        addCategoryError: null,
      }

    default:
      return state
  }
}

export default Categories
