import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import profileImg from "../../assets/images/profile-img.png"
import UserLogo from "../../assets/images/userLogo.png"

const WelcomeComp = () => {
  const [adminData, setAdminData] = useState({ username: "" })
  useEffect(() => {
    item()
  }, [])

  const item = () => {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    setAdminData(obj)
  }

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft" style={{ height: "170px" }}>
          <Row>
            <Col xs="8">
              <div className="text-primary p-3">
                <h1
                  className="text-primary ps-5 pt-3"
                  style={{ fontSize: "40px" }}
                >
                  Welcome Back !
                </h1>
                <h3 className="ps-5">Admin Dashboard</h3>
              </div>
            </Col>
            <Col xs="4" className="align-self-end">
              <img
                src={profileImg}
                alt=""
                className="img-fluid"
                style={{ height: "300px" }}
              />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4" className="ps-5 d-flex">
              <div
                className="avatar-md profile-user-wid mb-4"
                style={{ width: "103px" }}
              >
                <img
                  src={UserLogo}
                  alt=""
                  className="img-thumbnail rounded-circle"
                  style={{ height: "103px" }}
                />
              </div>
              <div className="ps-3 pt-2">
                <h2 className="font-size-20 text-truncate">
                  {adminData.displayName}
                </h2>
                <p className="font-size-17 text-muted mb-0 text-truncate">
                  Admin
                </p>
              </div>
            </Col>

            <Col sm="8"></Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
