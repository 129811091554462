import {
  GET_SITE_INFO,
  GET_SITE_INFO_SUCCESS,
  GET_SITE_INFO_FAIL,
  UPDATE_SITE_INFO,
  UPDATE_SITE_INFO_SUCCESS,
  UPDATE_SITE_INFO_FAIL,
  ADD_SITE_INFO,
  ADD_SITE_INFO_SUCCESS,
  ADD_SITE_INFO_FAIL,
  RESET_SITE_INFO_FLAG,
} from "./actionTypes"

export const getSiteInfo = () => ({
  type: GET_SITE_INFO,
})

export const getSiteInfoSuccess = siteInfo => ({
  type: GET_SITE_INFO_SUCCESS,
  payload: siteInfo,
})

export const getSiteInfoFail = error => ({
  type: GET_SITE_INFO_FAIL,
  payload: error,
})

export const updateSiteInfo = value => ({
  type: UPDATE_SITE_INFO,
  payload: value,
})

export const updateSiteInfoSuccess = data => ({
  type: UPDATE_SITE_INFO_SUCCESS,
  payload: data,
})

export const updateSiteInfoFail = error => ({
  type: UPDATE_SITE_INFO_FAIL,
  payload: error,
})

export const addSiteInfo = value => ({
  type: ADD_SITE_INFO,
  payload: value,
})

export const addSiteInfoSuccess = data => ({
  type: ADD_SITE_INFO_SUCCESS,
  payload: data,
})

export const addSiteInfoFail = error => ({
  type: ADD_SITE_INFO_FAIL,
  payload: error,
})

export const resetSiteInfoAlert = () => ({
  type: RESET_SITE_INFO_FLAG,
})
