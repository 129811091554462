import {
  GET_EVENTS,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAIL,
  GET_EVENT_DETAIL,
  GET_EVENT_DETAIL_SUCCESS,
  GET_EVENT_DETAIL_FAIL,
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  UPDATE_EVENT,
  UPDATE_EVENT_FAIL,
  UPDATE_EVENT_SUCCESS,
} from "./actionTypes"

export const getEvents = () => ({
  type: GET_EVENTS,
})

export const getEventsSuccess = events => ({
  type: GET_EVENTS_SUCCESS,
  payload: events,
})

export const getEventsFail = error => ({
  type: GET_EVENTS_FAIL,
  payload: error,
})

export const updateEvent = (eventId, value) => ({
  type: UPDATE_EVENT,
  payload: { eventId, value },
})

export const updateEventSuccess = event => ({
  type: UPDATE_EVENT_SUCCESS,
  payload: event,
})

export const updateEventFail = error => ({
  type: UPDATE_EVENT_FAIL,
  payload: error,
})

export const getEventDetail = id => ({
  type: GET_EVENT_DETAIL,
  payload: id,
})

export const getEventDetailSuccess = event => ({
  type: GET_EVENT_DETAIL_SUCCESS,
  payload: event,
})

export const getEventDetailFail = error => ({
  type: GET_EVENT_DETAIL_FAIL,
  payload: error,
})

export const deleteEvent = id => ({
  type: DELETE_EVENT,
  payload: id,
})

export const deleteEventSuccess = event => ({
  type: DELETE_EVENT_SUCCESS,
  payload: event,
})

export const deleteEventFail = error => ({
  type: DELETE_EVENT_FAIL,
  payload: error,
})
