import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
import DeleteModal from "../../components/Common/DeleteModal"

import { deleteUser, getUserSubscriptions } from "store/actions"

import {
  OrderId,
  Date,
  CommonField,
  PricingField,
  UserInfo,
} from "./SubscriptionCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Col, Row, UncontrolledTooltip, Card, CardBody } from "reactstrap"

function SubscriptionOrders() {
  //meta title
  document.title = "Plans | Tacticulture-Admin"

  const [user, setUser] = useState(null)

  const dispatch = useDispatch()
  const { userSubscriptions } = useSelector(state => ({
    userSubscriptions: state.UsersSubscriptions.userSubscriptions,
  }))

  useEffect(() => {
    if (userSubscriptions && !userSubscriptions.length) {
      dispatch(getUserSubscriptions())
    }
  }, [dispatch])

  //delete user
  const [deleteModal, setDeleteModal] = useState(false)

  const handleDeleteUser = () => {
    if (user && user.id) {
      dispatch(deleteUser(user.id))
      setDeleteModal(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "User Info",
        accessor: "customer",
        filterable: true,
        Cell: cellProps => {
          return <UserInfo {...cellProps} />
        },
      },
      {
        Header: "Subscription ID",
        accessor: "stripe_subscription_id",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Transaction ID",
        accessor: "transaction_id",
        filterable: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Plan Name",
        accessor: "plan.name",
        filterable: true,
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },

      {
        Header: "Plan Type",
        accessor: "plan.plan_type",
        filterable: true,
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },

      {
        Header: "Plan Cost",
        accessor: "plan.pricing",
        filterable: true,
        Cell: cellProps => {
          return <PricingField {...cellProps} />
        },
      },

      {
        Header: "Order Date",
        accessor: "payment_date",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },

      {
        Header: "Renew Date",
        accessor: "period_end",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={cellProps.row.original.payment_receipt}
                className="text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="mdi mdi-file-download font-size-18"
                  id="downloadtooltip"
                />
                <UncontrolledTooltip placement="top" target="downloadtooltip">
                  Download Invoice
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    [],
  )

  return (
    <React.Fragment>
      {/* <EventsDetailModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <h3 className="mb-4">Subscription Orders</h3>
          {/* <Breadcrumbs title="Users" breadcrumbItem="List" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    className="userTable"
                    columns={columns}
                    data={userSubscriptions}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    forFilter={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SubscriptionOrders
