import {
  GET_PRIVACY_POLICY,
  GET_PRIVACY_POLICY_SUCCESS,
  GET_PRIVACY_POLICY_FAIL,
  GET_TERMS_CONDITIONS,
  GET_TERMS_CONDITIONS_SUCCESS,
  GET_TERMS_CONDITIONS_FAIL,
  CREATE_PRIVACY_POLICY,
  CREATE_PRIVACY_POLICY_SUCCESS,
  CREATE_PRIVACY_POLICY_FAIL,
  CREATE_TERMS_CONDITIONS,
  CREATE_TERMS_CONDITIONS_SUCCESS,
  CREATE_TERMS_CONDITIONS_FAIL,
  UPDATE_PRIVACY_POLICY,
  UPDATE_PRIVACY_POLICY_SUCCESS,
  UPDATE_PRIVACY_POLICY_FAIL,
  UPDATE_TERMS_CONDITIONS,
  UPDATE_TERMS_CONDITIONS_SUCCESS,
  UPDATE_TERMS_CONDITIONS_FAIL,
  RESET_ALERT_FLAG,
} from "./actionTypes"

export const getPrivacyPolicy = () => ({
  type: GET_PRIVACY_POLICY,
})

export const getPrivacyPolicySuccess = data => ({
  type: GET_PRIVACY_POLICY_SUCCESS,
  payload: data,
})

export const getPrivacyPolicyFail = error => ({
  type: GET_PRIVACY_POLICY_FAIL,
  payload: error,
})

export const getTermsConditions = () => ({
  type: GET_TERMS_CONDITIONS,
})

export const getTermsConditionsSuccess = data => ({
  type: GET_TERMS_CONDITIONS_SUCCESS,
  payload: data,
})

export const getTermsConditionsFail = error => ({
  type: GET_TERMS_CONDITIONS_FAIL,
  payload: error,
})

export const createPrivacyPolicy = value => ({
  type: CREATE_PRIVACY_POLICY,
  payload: value,
})

export const createPrivacyPolicySuccess = data => ({
  type: CREATE_PRIVACY_POLICY_SUCCESS,
  payload: data,
})

export const createPrivacyPolicyFail = error => ({
  type: CREATE_PRIVACY_POLICY_FAIL,
  payload: error,
})

export const createTermsConditions = value => ({
  type: CREATE_TERMS_CONDITIONS,
  payload: value,
})

export const createTermsConditionsSuccess = data => ({
  type: CREATE_TERMS_CONDITIONS_SUCCESS,
  payload: data,
})

export const createTermsConditionsFail = error => ({
  type: CREATE_TERMS_CONDITIONS_FAIL,
  payload: error,
})

export const updatePrivacyPolicy = value => ({
  type: UPDATE_PRIVACY_POLICY,
  payload: value,
})

export const updatePrivacyPolicySuccess = data => ({
  type: UPDATE_PRIVACY_POLICY_SUCCESS,
  payload: data,
})

export const updatePrivacyPolicyFail = error => ({
  type: UPDATE_PRIVACY_POLICY_FAIL,
  payload: error,
})

export const updateTermsConditions = value => ({
  type: UPDATE_TERMS_CONDITIONS,
  payload: value,
})

export const updateTermsConditionsSuccess = data => ({
  type: UPDATE_TERMS_CONDITIONS_SUCCESS,
  payload: data,
})

export const updateTermsConditionsFail = error => ({
  type: UPDATE_TERMS_CONDITIONS_FAIL,
  payload: error,
})

export const resetAlertFlag = () => ({
  type: RESET_ALERT_FLAG,
})
