/* CATEGORIES */
export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"

/*UPDATE CATEGORY */
export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL"

/*CATEGORY DETAILS */
export const GET_CATEGORY_DETAIL = "GET_CATEGORY_DETAIL"
export const GET_CATEGORY_DETAIL_SUCCESS = "GET_CATEGORY_DETAIL_SUCCESS"
export const GET_CATEGORY_DETAIL_FAIL = "GET_CATEGORY_DETAIL_FAIL"

/**
 * CATEGORY DELETE
 */
export const DELETE_CATEGORY = "DELETE_CATEGORY"
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS"
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL"

/* ADD NEW CATEGORY */
export const ADD_CATEGORY = "ADD_CATEGORY"
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS"
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL"
export const RESET_ADD_CATEGORY_FLAG = "RESET_ADD_CATEGORY_FLAG"
